import { makeStyles } from '@material-ui/core/styles';

const useTextFieldStyles = makeStyles((theme) => ({
  textField: {
    margin: '4px 0 !important',
  },
  input: {
    fontSize: '1.6rem !important',
    lineHeight: '1.6rem !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem !important',
      lineHeight: '1.5rem !important',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem !important',
      lineHeight: '1.4rem !important',
    },
  },
  inputOutlinedLessPadding: {
    padding: '10px !important',
    [theme.breakpoints.down('sm')]: {
      padding: '8px !important',
    },
  },
  greyText: {
    color: '#aaaaaa !important',
  },
}));

export default useTextFieldStyles;
