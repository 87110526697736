import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import CenteredSectionContainer from '@components/common/CenteredSectionContainer';
import useStyles from './styles';

const ContactTabIntro = ({ selectedTab, connectIntro, joinIntro }) => {
  const classes = useStyles();

  return (
    <CenteredSectionContainer>
      <Typography variant="body2" className={classes.root}>
        {(selectedTab === 'connect') ? connectIntro : joinIntro}
      </Typography>
    </CenteredSectionContainer>
  );
};

ContactTabIntro.propTypes = {
  selectedTab: PropTypes.oneOf(['connect', 'join']).isRequired,
  connectIntro: PropTypes.string.isRequired,
  joinIntro: PropTypes.string.isRequired,
};

export default ContactTabIntro;
