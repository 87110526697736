import React, { useState } from 'react';
import { RadioGroup, FormControl, Grid } from '@material-ui/core';
import QuestionSection from '../QuestionSection';
import SimpleTextField from '../SimpleTextField';
import SimpleRadioLabel from '../SimpleRadioLabel';
import useStyles from './styles';

const YourInterest = () => {
  const classes = useStyles();
  const [selectedInterest, setSelectedInterest] = useState('friend');

  const handleChange = (event) => {
    setSelectedInterest(event.target.value);
  };

  return (
    <QuestionSection title="Interest">
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="Interest"
          name="your-interest"
          value={selectedInterest}
          onChange={handleChange}
        >
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <SimpleRadioLabel value="freelance" label="Freelance" />
            </Grid>
            <Grid item xs={12}>
              <SimpleRadioLabel value="full-time" label="Full-time" />
            </Grid>
            <Grid item xs={12}>
              <SimpleRadioLabel value="part-time" label="Part-time" />
            </Grid>
            <Grid item xs={12}>
              <SimpleRadioLabel value="collaboration" label="Collaboration" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SimpleRadioLabel value="other" label="Other" />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.textFieldGridItem}>
              <SimpleTextField name="interest-other" placeholder="Please specify." />
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
    </QuestionSection>
  );
};

export default YourInterest;
