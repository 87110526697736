import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  inner: {
    width: '100%',
    maxWidth: '500px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'none',
    },
  },
}));

export default useStyles;
