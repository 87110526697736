import React from 'react';
import PropTypes from 'prop-types';
import useTextFieldStyles from '@utils/useTextFieldStyles';
import { TextField } from '@material-ui/core';

const SimpleTextField = ({ name, placeholder }) => {
  const classes = useTextFieldStyles();
  return (
    <TextField
      name={name}
      label={false}
      placeholder={placeholder}
      variant="outlined"
      fullWidth
      classes={{ root: classes.textField }}
      InputLabelProps={{ classes: { root: classes.input } }}
      InputProps={{
        classes: {
          root: classes.input,
          input: classes.inputOutlinedLessPadding,
        },
      }}
    />
  );
};

SimpleTextField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default SimpleTextField;
