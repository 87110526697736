import React from 'react';
import QuestionSection from '../QuestionSection';
import SimpleSelectInput from '../SimpleSelectInput';
import useStyles from './styles';

const YourTimeline = () => {
  const classes = useStyles();

  return (
    <QuestionSection title="YOUR TIMELINE">
      <div className={classes.selectWrapper}>
        <SimpleSelectInput
          name="timeline"
          options={[
            {
              value: '1-3-months',
              label: '1 - 3 months',
            },
            {
              value: '4-6-months',
              label: '4 - 6 months',
            },
            {
              value: '7-12-months',
              label: '7 - 12 months',
            },
            {
              value: '12-months-or-more',
              label: '12 months or more',
            },
          ]}
        />
      </div>
    </QuestionSection>
  );
};

export default YourTimeline;
