import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Layout from '@layouts/default';
import SEO from '@components/meta/SEO';
import { graphql } from 'gatsby';
import PageHeader from '@components/common/PageHeader';
import SimpleTextSection from '@components/common/SimpleTextSection';
import SectionSpacer from '@components/common/SectionSpacer';
import ContactTabContainer from '@components/contact/ContactTabContainer';
import ContactTabIntro from '@components/contact/ContactTabIntro';
import YourContactInfo from '@components/contact/YourContactInfo';
import MultilineQuestion from '@components/contact/MultilineQuestion';
import YourChannel from '@components/contact/YourChannel';
import YourTimeline from '@components/contact/YourTimeline';
import SubmitButton from '@components/contact/SubmitButton';
import YourInterest from '@components/contact/YourInterest';

const ContactPage = ({ data }) => {
  const [selectedTab, setSelectedTab] = useState('connect');
  const [enteredFields, setEnteredFields] = useState([]);

  const handleSelect = (tab) => {
    setSelectedTab(tab);
    setEnteredFields([]);
  };

  const handleChange = (formName, input) => {
    setEnteredFields((prev) => {
      if (input && !prev.includes(formName)) {
        return [...prev, formName];
      }
      if (!input && prev.includes(formName)) {
        return prev.filter((name) => name !== formName);
      }
      return prev;
    });
  };

  const canSubmit = () => {
    let requiredFields;
    if (selectedTab === 'connect') {
      requiredFields = ['full-name', 'email', 'phone-number', 'your-project'];
    } else if (selectedTab === 'join') {
      requiredFields = ['full-name', 'email', 'phone-number', 'profile', 'fit'];
    }

    if (!requiredFields) return false;
    for (const field of requiredFields) { // eslint-disable-line no-restricted-syntax
      if (!enteredFields.includes(field)) return false;
    }
    return true;
  };

  return (
    <Layout>
      <SEO title="Contact" />
      <PageHeader
        pageTitle="CONTACT US"
        image={data.headerImage.childImageSharp.fluid}
      />
      <SimpleTextSection
        heading="LET’S TALK."
        text="We want to hear about everything. Maybe you can come to the studio. We'd love to give you a tour. Do you like snacks?"
      />
      <SectionSpacer
        xl="80px"
        lg="80px"
        md="60px"
        sm="40px"
        xs="40px"
      />
      <ContactTabContainer
        selectedTab={selectedTab}
        handleSelect={handleSelect}
      />
      <ContactTabIntro
        selectedTab={selectedTab}
        connectIntro="Use this form if you have a new project, product need or strange idea taking shape. Or if you just want to connect now to open doors for the future."
        joinIntro="Use this form if you love Moey more than cake and want to work for us. We’re always looking for new talent."
      />
      {selectedTab === 'connect' ? (
        <form
          noValidate
          autoComplete="off"
          data-netlify="true"
          name="connect"
          method="POST"
          data-netlify-honeypot="bot-field"
          action="/contact/thank-you/"
        >
          <input type="hidden" name="form-name" value="connect" />
          <input type="hidden" name="subject" value="Connect with Moey" />
          <YourContactInfo
            onFullNameChange={handleChange}
            onEmailChange={handleChange}
            onPhoneNumberChange={handleChange}
          />
          <MultilineQuestion
            required
            title="HOW CAN WE WORK TOGETHER?"
            name="your-project"
            placeholder="Tell us about your project, product need or anything else"
            onChange={handleChange}
          />
          <YourChannel />
          <YourTimeline />
          <SectionSpacer
            xl="80px"
            lg="80px"
            md="60px"
            sm="20px"
            xs="20px"
          />
          <SubmitButton
            disabled={!canSubmit()}
          />
          <SectionSpacer
            xl="80px"
            lg="80px"
            md="60px"
            sm="40px"
            xs="40px"
          />
        </form>
      ) : (
        <form
          noValidate
          autoComplete="off"
          data-netlify="true"
          name="join"
          method="POST"
          data-netlify-honeypot="bot-field"
          action="/contact/thank-you/"
        >
          <input type="hidden" name="form-name" value="join" />
          <input type="hidden" name="subject" value="Join team Moey" />
          <MultilineQuestion
            required
            title="Profile"
            name="profile"
            placeholder="Tell us about you"
            onChange={handleChange}
          />
          <MultilineQuestion
            required
            title="Fit"
            name="fit"
            placeholder="What can you bring to Moey?"
            onChange={handleChange}
          />
          <YourInterest />
          <YourChannel />
          <YourContactInfo
            onFullNameChange={handleChange}
            onEmailChange={handleChange}
            onPhoneNumberChange={handleChange}
          />
          <SectionSpacer
            xl="80px"
            lg="80px"
            md="60px"
            sm="20px"
            xs="20px"
          />
          <SubmitButton
            disabled={!canSubmit()}
          />
          <SectionSpacer
            xl="80px"
            lg="80px"
            md="60px"
            sm="40px"
            xs="40px"
          />
        </form>
      )}
      <form name="join" data-netlify="true" data-netlify-honeypot="bot-field" hidden>
        <input type="hidden" name="form-name" value="join" />
        <textarea name="profile" />
        <textarea name="fit" />
        <input name="your-interest" type="radio" />
        <input name="interest-other" type="text" />
        <input name="channel" type="radio" />
        <input name="channel-conference" type="text" />
        <input name="channel-moey-work" type="text" />
        <input name="channel-already-familiar" type="text" />
        <input name="channel-other" type="text" />
        <input name="full-name" type="text" />
        <input name="email" type="text" />
        <input name="phone-number" type="text" />
        <input name="organization" type="text" />
        <input name="location" type="text" />
      </form>
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired, // eslint-disable-line
};

export default ContactPage;

export const pageQuery = graphql`
  query {
    headerImage: file(relativePath: {eq: "page-header-contact.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
