import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  selectWrapper: {
    width: '450px',
    [theme.breakpoints.down('sm')]: {
      width: '300px',
    },
  },
}));

export default useStyles;
