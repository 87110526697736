import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useTextFieldStyles from '@utils/useTextFieldStyles';
import { TextField, MenuItem } from '@material-ui/core';

const SimpleTextField = ({ name, initialValue, options, helperText }) => {
  const classes = useTextFieldStyles();
  const [selectedOption, setSelectedOption] = useState(initialValue);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <TextField
      name={name}
      label={false}
      select
      placeholder="Select One"
      value={selectedOption}
      onChange={handleChange}
      variant="outlined"
      fullWidth
      SelectProps={{ defaultValue: 'Select One', displayEmpty: true }}
      helperText={helperText}
      classes={{ root: classes.textField }}
      InputLabelProps={{ classes: { root: classes.input } }}
      InputProps={{
        classes: {
          root: classes.input,
          input: classes.inputOutlinedLessPadding,
        },
      }}
      FormHelperTextProps={{ classes: { root: classes.greyText } }}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

SimpleTextField.defaultProps = {
  initialValue: '',
  helperText: '',
};

SimpleTextField.propTypes = {
  name: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
  helperText: PropTypes.string,
};

export default SimpleTextField;
