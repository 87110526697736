import { makeStyles } from '@material-ui/core/styles';
import { PALETTE } from '@constants';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: '150px',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      height: '80px',
    },
  },
  tab: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: (props) => (props.right ? 'flex-start' : 'flex-end'),
    width: '100%',
    height: '100%',
    backgroundColor: (props) => (props.selected ? PALETTE.moeyBlue : '#eaeaea'),
    border: 'none',
    cursor: 'pointer',
    padding: '0 100px',
    [theme.breakpoints.down('sm')]: {
      padding: '0',
      justifyContent: () => 'center',
    },
  },
  arrow: {
    position: 'absolute',
    top: 'calc(100% - 30px)',
    right: (props) => (props.right ? 'auto' : '250px'),
    left: (props) => (props.right ? '250px' : 'auto'),
    width: '60px',
    height: '60px',
    transform: 'rotate(45deg)',
    backgroundColor: PALETTE.moeyBlue,
    [theme.breakpoints.down('sm')]: {
      top: 'calc(100% - 4px)',
      right: () => 'auto',
      left: () => '50%',
      width: '30px',
      height: '30px',
      transform: 'rotate(45deg) translateX(-50%)',
    },
  },
}));

export default useStyles;
