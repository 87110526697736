import React from 'react';
import PropTypes from 'prop-types';
import useTextFieldStyles from '@utils/useTextFieldStyles';
import { TextField } from '@material-ui/core';
import QuestionSection from '../QuestionSection';

const YourContactInfo = ({ onFullNameChange, onEmailChange, onPhoneNumberChange }) => {
  const classes = useTextFieldStyles();

  return (
    <QuestionSection lessMarginTop title="HOW CAN WE REACH YOU?">
      <TextField
        required
        name="full-name"
        label="Full Name"
        fullWidth
        classes={{ root: classes.textField }}
        InputLabelProps={{ classes: { root: classes.input } }}
        InputProps={{ classes: { root: classes.input } }}
        onChange={(e) => onFullNameChange('full-name', e.target.value)}
      />
      <TextField
        required
        name="email"
        label="Email"
        fullWidth
        classes={{ root: classes.textField }}
        InputLabelProps={{ classes: { root: classes.input } }}
        InputProps={{ classes: { root: classes.input } }}
        onChange={(e) => onEmailChange('email', e.target.value)}
      />
      <TextField
        required
        name="phone-number"
        label="Phone Number"
        fullWidth
        classes={{ root: classes.textField }}
        InputLabelProps={{ classes: { root: classes.input } }}
        InputProps={{ classes: { root: classes.input } }}
        onChange={(e) => onPhoneNumberChange('phone-number', e.target.value)}
      />
      <TextField
        name="organization"
        label="Organization / Company"
        fullWidth
        classes={{ root: classes.textField }}
        InputLabelProps={{ classes: { root: classes.input } }}
        InputProps={{ classes: { root: classes.input } }}
      />
      <TextField
        name="location"
        label="Location"
        fullWidth
        classes={{ root: classes.textField }}
        InputLabelProps={{ classes: { root: classes.input } }}
        InputProps={{ classes: { root: classes.input } }}
      />
    </QuestionSection>
  );
};

YourContactInfo.defaultProps = {
  onFullNameChange: () => {},
  onEmailChange: () => {},
  onPhoneNumberChange: () => {},
};

YourContactInfo.propTypes = {
  onFullNameChange: PropTypes.func,
  onEmailChange: PropTypes.func,
  onPhoneNumberChange: PropTypes.func,
};

export default YourContactInfo;
