import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import CenteredSectionContainer from '@components/common/CenteredSectionContainer';
import SectionSpacer from '@components/common/SectionSpacer';
import useStyles from './styles';

const QuestionSection = ({ title, children, lessMarginTop }) => {
  const classes = useStyles({ lessMarginTop });

  return (
    <>
      <CenteredSectionContainer>
        <Typography variant="h3" className={classes.title}>
          { title }
        </Typography>
        { children }
      </CenteredSectionContainer>
      <SectionSpacer xl="80px" md="70px" sm="50px" />
    </>
  );
};

QuestionSection.defaultProps = {
  lessMarginTop: false,
};

QuestionSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  lessMarginTop: PropTypes.bool,
};

export default QuestionSection;
