import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  textFieldGridItem: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '30px',
    },
  },
}));

export default useStyles;
