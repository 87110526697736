import React from 'react';
import PropTypes from 'prop-types';
import useTextFieldStyles from '@utils/useTextFieldStyles';
import { TextField } from '@material-ui/core';
import QuestionSection from '../QuestionSection';

const MultilineQuestion = ({ title, name, placeholder, required, onChange }) => {
  const classes = useTextFieldStyles();

  return (
    <QuestionSection title={title}>
      <TextField
        required={required}
        name={name}
        multiline
        rows="6"
        fullWidth
        placeholder={placeholder}
        label={required ? 'Required' : false}
        variant="outlined"
        classes={{ root: classes.textField }}
        InputLabelProps={{ classes: { root: classes.input }, shrink: true }}
        InputProps={{ classes: { root: classes.input } }}
        onChange={(e) => onChange(name, e.target.value)}
      />
    </QuestionSection>
  );
};

MultilineQuestion.defaultProps = {
  required: false,
  onChange: () => {},
};

MultilineQuestion.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func,
};

export default MultilineQuestion;
