import React from 'react';
import PropTypes from 'prop-types';
import { Radio, FormControlLabel } from '@material-ui/core';
import useStyles from './styles';

const SimpleRadioLabel = ({ value, label }) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      value={value}
      control={<Radio color="primary" classes={{ root: classes.radio }} />}
      label={label}
    />
  );
};

SimpleRadioLabel.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default SimpleRadioLabel;
