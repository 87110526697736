import React from 'react';
import PropTypes from 'prop-types';
import ContactTab from './ContactTab';
import useStyles from './styles';

const ContactTabContainer = ({ selectedTab, handleSelect }) => {
  const classes = useStyles();

  return (
    <div className={classes.tabContainer}>
      <ContactTab
        label="CONNECT"
        selected={selectedTab === 'connect'}
        onClick={() => handleSelect('connect')}
      />
      <ContactTab
        right
        label="JOIN"
        selected={selectedTab === 'join'}
        onClick={() => handleSelect('join')}
      />
    </div>
  );
};

ContactTabContainer.propTypes = {
  selectedTab: PropTypes.oneOf(['connect', 'join']).isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default ContactTabContainer;
