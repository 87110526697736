import React, { useState } from 'react';
import { RadioGroup, FormControl, Grid } from '@material-ui/core';
import QuestionSection from '../QuestionSection';
import SimpleTextField from '../SimpleTextField';
import SimpleRadioLabel from '../SimpleRadioLabel';
import useStyles from './styles';

const YourContactInfo = () => {
  const classes = useStyles();
  const [selectedChannel, setSelectedChannel] = useState('');

  const handleChange = (event) => {
    setSelectedChannel(event.target.value);
  };

  return (
    <QuestionSection title="HOW DID YOU HEAR ABOUT MOEY?">
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="How did you hear about moey?"
          name="channel"
          value={selectedChannel}
          onChange={handleChange}
        >
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <SimpleRadioLabel value="friend" label="Friend or colleague" />
            </Grid>
            <Grid item xs={12}>
              <SimpleRadioLabel value="internet" label="Internet search" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SimpleRadioLabel value="conference" label="Conference" />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.textFieldGridItem}>
              <SimpleTextField name="channel-conference" placeholder="Which one?" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SimpleRadioLabel value="moey-work" label="Saw Moey’s work" />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.textFieldGridItem}>
              <SimpleTextField name="channel-moey-work" placeholder="Where?" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SimpleRadioLabel value="already-familiar" label="Already familiar with Moey" />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.textFieldGridItem}>
              <SimpleTextField name="channel-already-familiar" placeholder="How so?" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SimpleRadioLabel value="other" label="Other" />
            </Grid>
            <Grid item xs={12} sm={6} className={classes.textFieldGridItem}>
              <SimpleTextField name="channel-other" placeholder="Please specify." />
            </Grid>
          </Grid>
        </RadioGroup>
      </FormControl>
    </QuestionSection>
  );
};

export default YourContactInfo;
