import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '120px 0 60px',
    [theme.breakpoints.down('md')]: {
      padding: '100px 0 50px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '60px 0 50px',
    },
  },
}));

export default useStyles;
