import React from 'react';
import PropTypes from 'prop-types';
import CenteredSectionContainer from '@components/common/CenteredSectionContainer';
import CTAButton from '@components/common/CTAButton';
import useStyles from './styles';

const SubmitButton = ({ disabled }) => {
  const classes = useStyles();

  return (
    <CenteredSectionContainer>
      <div className={classes.container}>
        <div className={classes.inner}>
          <CTAButton
            disabled={disabled}
            label="SEND"
            width="100%"
            noLine
            thick
            buttonType={disabled ? 'button' : 'submit'}
            onClick={() => {}}
          />
        </div>
      </div>
    </CenteredSectionContainer>
  );
};

SubmitButton.defaultProps = {
  disabled: false,
};

SubmitButton.propTypes = {
  disabled: PropTypes.bool,
};

export default SubmitButton;
