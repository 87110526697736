import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import useStyles from './styles';

const ContactTab = ({ label, selected, right, onClick }) => {
  const classes = useStyles({ selected, right });

  return (
    <div className={classes.root}>
      <button
        className={classes.tab}
        onClick={onClick}
        type="button"
      >
        <Typography variant="h2" color="textSecondary">
          { label }
        </Typography>
      </button>
      {selected ? <div className={classes.arrow} /> : null}
    </div>
  );
};

ContactTab.defaultProps = {
  right: false,
};

ContactTab.propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  right: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default ContactTab;
